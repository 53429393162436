import styles from "../styles.module.scss";
import ContactForm from "./contactForm";
import ContactAlt from "./contactAlt";

function Contact() {
  return (
    <div className={`${styles.akpContact} ${styles.akpBlock}`} id="contact">
      <h1>
        Ota yhteyttä
        <svg
          width="68"
          height="43"
          viewBox="0 0 68 43"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.89648 2.50452L2.17121 34.2673C2.07678 35.3678 2.89239 36.3365 3.99291 36.4309L61.027 41.3245C62.1275 41.4189 63.0962 40.6033 63.1907 39.5028L65.916 7.74005M4.89648 2.50452L65.916 7.74005M4.89648 2.50452L32.3955 21.3193C32.9639 21.7082 33.6923 21.7773 34.3236 21.5023L65.916 7.74005"
            stroke="#C40000"
            stroke-width="3"
          />
        </svg>
      </h1>
      <div className={styles.akpContactCont}>
        <ContactForm />
        <ContactAlt />
      </div>
    </div>
  );
}

export default Contact;
