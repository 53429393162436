import styles from "../styles.module.scss";
import InfoShowcase from "./infoShowcase";

function Info() {
  return (
    <div className={`${styles.akpInfo} ${styles.akpBlock}`} id="info">
      <div className={styles.akpInfoText}>
        <h3>Oletko myymässä tai vuokraamassa asuntoa Jyväskylän alueella?</h3>
        <p>
          Tasokkaat asuntokuvat erottavat ilmoituksesi massasta ja herättävät
          herkemmin potentiaalisten asiakkaiden huomion. <br />
          Tarjoan ammattitason asuntokuvauspalvelua Jyväskylässä ja sen
          lähialueilla hyvin kilpailukykyiseen hintaan.
        </p>

        <p>
          Palveluuni kuuluvat normaalien asuntokuvien lisäksi asuntovideot,
          ilmakuvat, 360-virtuaalikuvaus, sekä siistit pohjapiirrokset
          asunnosta.
        </p>

        <p>
          Hinnat sisältävät matkat 10 kilometrin säteellä Jyväskylästä. Sitä
          kauempanakin kuvaus onnistuu, mutta 10 kilometriä ylittävältä matkalta
          perin kilometrikorvauksen (90snt/km).
        </p>

        <p>
          Ota rohkeasti yhteyttä, niin katsotaan sinulle parhaiten sopiva
          kuvauspaketti!
        </p>
      </div>
      <InfoShowcase />
    </div>
  );
}

export default Info;
