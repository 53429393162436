import styles from "../styles.module.scss";
import PricingCard from "./pricingCard";
import PricingDescItem from "./pricingDescItem";


function Pricing() {
  return (
    <div className={`${styles.akpPricing} ${styles.akpFullBlock}`}>
      <div
        className={`${styles.akpBlock} ${styles.akpPricingCont}`}
        id="pricing"
      >
        <h1>Hinnasto</h1>
        <div className={styles.akpPricingList}>
          <PricingCard
            CardIcon="SmallHouse"
            CardName="Yksiö / kaksio"
            OldPrice="160 €"
            CardPrice="140 €"
          />
          <PricingCard
            CardIcon="MediumHouse"
            CardName="Kolmio / neliö"
            OldPrice="190 €"
            CardPrice="160 €"
          />
          <PricingCard
            CardIcon="LargeHouse"
            CardName="Isommat asunnot"
            OldPrice="250 €"
            CardPrice="200 €"
          />
        </div>
        <h4>Lisäpalvelut:</h4>
        <div className={styles.akpPricingList}>
          <PricingCard
            CardIcon="Drone"
            CardName="Ilmakuvaus"
            CardPrice="+55 €"
            //CardFlip="Drone"

          />

          <PricingCard
            CardIcon="Movie"
            CardName="Asuntovideo"
            CardPrice="+80 €"
          />
          <PricingCard
            CardIcon="Gimbal"
            CardName="360°-kuvaus"
            CardPrice="+100 €"
          />
          <PricingCard
            CardIcon="Blueprint"
            CardName="Pohjapiirros"
            CardPrice="+100 €"
            CardNote="Lisätietoa"
          />
        </div>
        <h4>Kaikki hinnat sisältävät:</h4>
        <div className={styles.akpPricingDesc}>
          <PricingDescItem ItemText="Kattava valokuvaus asunnosta" />
          <PricingDescItem ItemText="Kuvien jälkikäsittely tietokoneella" />
          <PricingDescItem ItemText="Toimitus kahden arkipäivän sisällä kuvauksesta" />
          <PricingDescItem ItemText="Matkakulut 10km säteellä Jyväskylästä" />
          <PricingDescItem ItemText="Latauslinkki ja vapaat käyttöoikeudet valmiisiin kuviin" />
          <PricingDescItem ItemText="Arvonlisäveron 25,5%" />
        </div>
      </div>
    </div>
  );
}

export default Pricing;
