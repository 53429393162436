import styles from "../styles.module.scss";
import NavigationLink from "./navigationLink";
import { useState } from "react";

function Navigation() {
  const [isActive, setActive] = useState("false");
  const ToggleClass = () => {
    setActive(!isActive);
  };

  return (
    <div
      className={`${styles.akpNavigationCont} ${
        isActive ? "" : `${styles.navOpen}`
      }`}
    >
      <button
        tabIndex={0}
        type="button"
        onClick={ToggleClass}
        className={styles.akpNavigationMobile}
        aria-label="Mobiilimenu"
      >
        <div></div>
      </button>
      <div className={styles.akpNavigation}>
        <NavigationLink LinkText="Info" LinkTarget="info" />
        <NavigationLink LinkText="Hinnasto" LinkTarget="pricing" />
        <NavigationLink LinkText="Ota yhteyttä" LinkTarget="contact" />
      </div>
    </div>
  );
}

export default Navigation;
