import styles from "../styles.module.scss";
import ModalImage from "react-modal-image";

import showcase1 from "../media/showcase1.jpg";
import showcase1s from "../media/showcase1-s.jpg";
import showcase2 from "../media/showcase2.jpg";
import showcase2s from "../media/showcase2s.jpg";
import showcase3 from "../media/showcase3.jpg";
import showcase3s from "../media/showcase3s.jpg";
import showcase4 from "../media/showcase4.jpg";
import showcase4s from "../media/showcase4s.jpg";
import showcase5 from "../media/showcase5.jpg";
import showcase5s from "../media/showcase5s.jpg";
import showcase6 from "../media/showcase6.png";
import showcase6s from "../media/showcase6s.png";

function InfoShowcase() {
  return (
    <div className={styles.akpInfoShowcase}>
      <ModalImage
        small={showcase1s}
        large={showcase1}
        hideDownload={true}
        alt="Esimerkki ilmakuvasta"
      />
      <ModalImage
        small={showcase2s}
        large={showcase2}
        hideDownload={true}
        alt="Esimerkkikuva olohuoneesta"
      />
      <ModalImage
        small={showcase3s}
        large={showcase3}
        hideDownload={true}
        alt="Esimerkkikuva olohuoneesta"
      />
      <ModalImage
        small={showcase4s}
        large={showcase4}
        hideDownload={true}
        alt="Esimerkkikuva terassilta"
      />
      <ModalImage
        small={showcase5s}
        large={showcase5}
        hideDownload={true}
        alt="Esimerkkikuva pesutiloista"
      />
      <ModalImage
        small={showcase6s}
        large={showcase6}
        hideDownload={true}
        alt="Esimerkki pohjapiirroksesta"
      />
    </div>
  );
}

export default InfoShowcase;
