import styles from "../styles.module.scss";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

function AkpButton(props) {
  return (
    <button
      className={`${styles.akpButton} ${
        props.secondary ? `${styles.secondary}` : ""
      }`}
      onClick={scrollToTop}
    >
      {props.ButtonText}
    </button>
  );
}

export default AkpButton;
