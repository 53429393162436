import styles from "../styles.module.scss";
import { useState } from "react";
import { send } from "emailjs-com";

function ContactForm() {
  const [toSend, setToSend] = useState({
    from_name: "",
    phone: "",
    message: "",
    reply_to: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    send("service_656qu3k", "template_g18ettu", toSend, "MuaxgIMKYt8TARyUx")
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        setSubmitted(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log("FAILED...", err);
        setLoading(false);
        alert("Jokin meni pieleen. :( Yritä myöhemmin uudelleen.");
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <div className={styles.akpContactForm}>
      {submitted ? (
        <div className={styles.akpContactFormSubmitted}>
          Kiitos yhteydenotosta!
        </div>
      ) : (
        <form onSubmit={onSubmit}>
          <div className={styles.akpContactFormInputLabel}>
            Nimi
            <span className={styles.akpContactFormInputRequired}>*</span>
          </div>
          <input
            type="text"
            required
            name="from_name"
            value={toSend.from_name}
            onChange={handleChange}
          />
          <div className={styles.akpContactFormInputLabel}>Sähköposti</div>
          <input
            type="email"
            name="reply_to"
            value={toSend.reply_to}
            onChange={handleChange}
          />
          <div className={styles.akpContactFormInputLabel}>
            Puhelin
            <span className={styles.akpContactFormInputRequired}>*</span>
          </div>
          <input
            type="tel"
            required
            minLength={7}
            name="phone"
            value={toSend.phone}
            onChange={handleChange}
          />
          <div className={styles.akpContactFormInputLabel}>
            Viesti
            <span className={styles.akpContactFormInputRequired}>*</span>
          </div>
          <textarea
            name="message"
            required
            rows="6"
            maxLength="3200"
            placeholder="Millainen kohde ja missä?&#10;Mistä palveluista olisit kiinnostunut?&#10;Ajankohta?"
            value={toSend.message}
            onChange={handleChange}
          />
          <button className={styles.akpButton} type="submit">
            Lähetä
          </button>
        </form>
      )}
      {loading && <div className={styles.loadingSpinner}></div>}
    </div>
  );
}

export default ContactForm;
