export default function AkpIcon(props) {
  if (props.IconName === "SmallHouse") {
    return (
        <svg width="75" height="77" viewBox="0 0 75 77" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.9783 75H39.1557H48.2633M27.9783 75H13.0053C11.9008 75 11.0053 74.1046 11.0053 73V43.0173C11.0053 41.9127 10.1099 41.0173 9.00534 41.0173H3.78322C2.00784 41.0173 1.10367 38.8854 2.35065 37.6216C9.48957 30.3868 26.085 13.5684 36.0816 3.4375C36.8647 2.64383 38.1405 2.64907 38.9236 3.44275L44.4259 9.01897C45.6819 10.2919 47.8495 9.40248 47.8495 7.61422V4C47.8495 2.89543 48.745 2 49.8495 2H55.7848C56.8893 2 57.7848 2.89543 57.7848 4V21.7367C57.7848 22.2625 57.9918 22.7672 58.3611 23.1414L72.6404 37.6126C73.8874 38.8763 72.9922 41.0173 71.2168 41.0173H66.8224C65.7178 41.0173 64.8224 41.9127 64.8224 43.0173V73C64.8224 74.1046 63.9269 75 62.8224 75H48.2633M27.9783 75V43.0173C27.9783 41.9127 28.8737 41.0173 29.9783 41.0173H46.2633C47.3679 41.0173 48.2633 41.9127 48.2633 43.0173V75" stroke="white" stroke-width="3"/>
        </svg>
        );
    }
    if(props.IconName === 'MediumHouse'){
        return (
            <svg width="92" height="81" viewBox="0 0 92 81" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.4317 79H34.5133H43.5427M23.4317 79H15.2359C14.1313 79 13.2359 78.1046 13.2359 77L13.2358 39.7216C13.2358 38.6171 12.3404 37.7216 11.2358 37.7216H4.30195C2.88317 37.7216 1.91551 36.2854 2.44837 34.9705L12.7298 9.59937C13.0357 8.84448 13.7688 8.35052 14.5833 8.35052H48.7443H58.3961C59.5007 8.35052 60.3961 7.45508 60.3961 6.35052V4C60.3961 2.89543 61.2915 2 62.3961 2H65.6282C66.7328 2 67.6282 2.89543 67.6282 4V6.35051C67.6282 7.45508 68.5237 8.35052 69.6282 8.35052H78.8688C79.701 8.35052 80.4462 8.86586 80.74 9.6445L90.3125 35.0156C90.8061 36.3237 89.8394 37.7216 88.4413 37.7216H82.2518C81.1472 37.7216 80.2518 38.6171 80.2518 39.7217L80.2518 77C80.2518 78.1046 79.3564 79 78.2518 79H43.5427M23.4317 79V49.2474C23.4317 48.1429 24.3272 47.2474 25.4317 47.2474H41.5427C42.6473 47.2474 43.5427 48.1429 43.5427 49.2474V79M55.5657 65.5052H69.6461C70.7507 65.5052 71.6461 64.6097 71.6461 63.5052V49.2474C71.6461 48.1429 70.7507 47.2474 69.6461 47.2474H55.5657C54.4612 47.2474 53.5657 48.1428 53.5657 49.2474V63.5052C53.5657 64.6097 54.4612 65.5052 55.5657 65.5052Z" stroke="white" stroke-width="3"/>
            </svg> 
        );
    }
    if(props.IconName === 'LargeHouse'){
        return (
            <svg width="93" height="89" viewBox="0 0 93 89" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M91.0848 21.701L91.0848 45.7216L91.0848 85C91.0848 86.1046 90.1894 87 89.0848 87H53.1665M91.0848 21.701H33.6665C32.5619 21.701 31.6665 20.8056 31.6665 19.701V14.4367C31.6665 13.8432 31.4029 13.2803 30.9469 12.9003L24.1665 7.25M91.0848 21.701L87.5722 9.14068C87.332 8.28169 86.552 7.6856 85.6601 7.67937L24.1665 7.25M53.1665 87H37.7648M53.1665 87V63.5C53.1665 62.3954 52.2711 61.5 51.1665 61.5H39.7648C38.6602 61.5 37.7648 62.3954 37.7648 63.5V87M37.7648 87H3.66656C2.56199 87 1.66656 86.1046 1.66656 85L1.6665 45.7216L1.6665 14.4367C1.6665 13.8432 1.93015 13.2803 2.38614 12.9003L15.3861 2.06697C16.1278 1.4489 17.2052 1.4489 17.9469 2.06697L24.1665 7.25M26.1665 63.5V73.7729C26.1665 74.8775 25.2711 75.7729 24.1665 75.7729H14.6665C13.5619 75.7729 12.6665 74.8775 12.6665 73.7729V63.5C12.6665 62.3954 13.5619 61.5 14.6665 61.5H24.1665C25.2711 61.5 26.1665 62.3954 26.1665 63.5ZM78.6665 34.5V44.7729C78.6665 45.8775 77.7711 46.7729 76.6665 46.7729H67.1665C66.0619 46.7729 65.1665 45.8775 65.1665 44.7729V34.5C65.1665 33.3954 66.0619 32.5 67.1665 32.5H76.6665C77.7711 32.5 78.6665 33.3954 78.6665 34.5ZM52.1665 34.5V44.7729C52.1665 45.8775 51.2711 46.7729 50.1665 46.7729H40.6665C39.5619 46.7729 38.6665 45.8775 38.6665 44.7729V34.5C38.6665 33.3954 39.5619 32.5 40.6665 32.5H50.1665C51.2711 32.5 52.1665 33.3954 52.1665 34.5ZM26.1665 34.5V44.7729C26.1665 45.8775 25.2711 46.7729 24.1665 46.7729H14.6665C13.5619 46.7729 12.6665 45.8775 12.6665 44.7729V34.5C12.6665 33.3954 13.5619 32.5 14.6665 32.5H24.1665C25.2711 32.5 26.1665 33.3954 26.1665 34.5ZM67.1665 75.7729H76.6665C77.7711 75.7729 78.6665 74.8775 78.6665 73.7729V63.5C78.6665 62.3954 77.7711 61.5 76.6665 61.5H67.1665C66.0619 61.5 65.1665 62.3954 65.1665 63.5V73.7729C65.1665 74.8775 66.0619 75.7729 67.1665 75.7729Z" stroke="white" stroke-width="3"/>
            </svg>
        );
    }
    if(props.IconName === 'Drone'){
        return (
            <svg width="144" height="47" viewBox="0 0 144 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M124.672 11C119.396 12.0165 117.355 13.8995 115.724 18.3524C115.382 19.2877 114.441 19.8821 113.458 19.7235L89.0013 15.2248C88.1658 15.09 87.5087 14.4365 87.3335 13.6086C85.1675 3.37109 78.3335 2 72.1718 2C66.1062 2 57.4995 3.37109 55.3335 13.6086C55.1583 14.4365 54.5012 15.09 53.6657 15.2248L30.0476 19.7235C29.0643 19.8821 28.1235 19.2877 27.781 18.3524C26.1507 13.8995 24.1091 12.0165 18.8336 11M124.672 11C131.646 12.1199 133.609 14.0393 133.172 20C131.996 28.8983 130.598 38.7696 126.349 44.4056C125.575 45.433 124.057 45.3817 123.29 44.3809C123.229 44.3006 123.181 44.2107 123.139 44.1188L115.723 27.8831C115.681 27.7912 115.634 27.7014 115.572 27.6213C115.259 27.2151 114.798 26.9421 114.287 26.8655C101.969 25.0178 95.3625 25.1183 85.4325 26.7835C84.6645 26.9123 83.9675 27.4677 83.8335 28.2348C81.5393 41.3687 80.0787 45 72.1718 45C65.1718 45 61.9661 41.3687 59.6719 28.2348C59.5379 27.4677 58.8409 26.9123 58.0729 26.7835C48.1428 25.1183 41.5363 25.0178 29.2182 26.8655C28.7077 26.9421 28.2465 27.2151 27.9335 27.6213C27.8718 27.7014 27.8244 27.7912 27.7824 27.8831L20.3663 44.1188C20.3243 44.2107 20.2768 44.3006 20.2153 44.3809C19.4481 45.3817 17.9305 45.433 17.156 44.4056C12.9075 38.7696 11.5098 28.8983 10.3336 20C9.89681 14.0393 11.8596 12.1199 18.8336 11M124.672 11V3M124.672 3H103.672M124.672 3H143.672M18.8336 11V3M18.8336 3H38.3336M18.8336 3H0.333584" stroke="white" stroke-width="3"/>
                <circle cx="71.6719" cy="30.5" r="7" stroke="white" stroke-width="3"/>
            </svg>
        );
    }
    if(props.IconName === 'Movie'){
        return (
            <svg width="75" height="89" viewBox="0 0 75 89" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.3335 30.2118V39.7488V50.1527M2.3335 30.2118L57.874 1.90242C58.8536 1.40309 60.0526 1.78822 60.5583 2.76462L64.7573 10.8734C65.2762 11.8755 64.8638 13.1082 63.8464 13.5963L9.3335 39.7488M2.3335 30.2118L9.3335 39.7488M2.3335 30.2118V50.1527M9.3335 39.7488H71.3335C72.4381 39.7488 73.3335 40.6442 73.3335 41.7488V50.1527V79C73.3335 83.4183 69.7518 87 65.3335 87H10.3335C5.91522 87 2.3335 83.4183 2.3335 79V50.1527M9.3335 39.7488L2.3335 50.1527M27.3335 70.1572V58.2314C27.3335 56.8789 28.648 55.9163 29.9374 56.3247L48.1636 62.0974C50.0068 62.6811 50.0313 65.2805 48.1995 65.8989L29.9732 72.0522C28.6768 72.4899 27.3335 71.5256 27.3335 70.1572Z" stroke="white" stroke-width="3"/>
            </svg>
        );
    }
    if(props.IconName === 'Gimbal'){
        return (
            <svg width="73" height="111" viewBox="0 0 73 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M42.1647 48.5217V107C42.1647 108.105 41.2693 109 40.1647 109H27.4C26.2954 109 25.4 108.105 25.4 107V48.5217M42.1647 48.5217H25.4M42.1647 48.5217H50.2118M25.4 48.5217H19.3529C18.2484 48.5217 17.3529 47.6263 17.3529 46.5217V41.5C17.3529 40.3954 18.2484 39.5 19.3529 39.5H50.2118M50.2118 39.5V48.5217M50.2118 39.5L64.3536 25.8507C64.7441 25.4738 64.9647 24.9543 64.9647 24.4116V19.9441M50.2118 48.5217L70.4544 27.078C70.8048 26.7068 71 26.2156 71 25.7051V13.3043C71 12.1998 70.1046 11.3043 69 11.3043H66.9647C65.8601 11.3043 64.9647 12.1998 64.9647 13.3043V14.6273M64.9647 14.6273C62.2824 14.6273 57.3647 14.6273 52.8941 14.6273M64.9647 14.6273V19.9441M64.9647 19.9441H52.8941M14 7V27.5714C14 30.3329 16.2386 32.5714 19 32.5714H47.8941C50.6555 32.5714 52.8941 30.3328 52.8941 27.5714V7C52.8941 4.23858 50.6555 2 47.8941 2H19C16.2386 2 14 4.23858 14 7Z" stroke="white" stroke-width="3"/>
                <circle cx="33.5" cy="17.5" r="11" stroke="white" stroke-width="3"/>
            </svg>
        );
    }
    if(props.IconName === 'Blueprint'){
        return (
            <svg width="71" height="77" viewBox="0 0 71 77" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M42.9942 37.8182H67C68.1046 37.8182 69 36.9227 69 35.8182V6C69 4.89543 68.1046 4 67 4H42.9942M42.9942 37.8182V27.6364M42.9942 37.8182V45.0351M42.9942 4H8C6.89543 4 6 4.89543 6 6V45.0351M42.9942 4V14.9091M17.7209 63.2727H8C6.89543 63.2727 6 62.3773 6 61.2727V45.0351M17.7209 63.2727V73.3623C17.7209 74.6869 18.9901 75.6526 20.2497 75.243C26.2555 73.2904 29.1828 70.8901 30.5407 63.2727M17.7209 63.2727H30.5407M30.5407 63.2727H40.9942C42.0988 63.2727 42.9942 62.3773 42.9942 61.2727V45.0351M42.9942 45.0351H30.3576M6 45.0351H17.7209" stroke="white" stroke-width="3"/>
            </svg>
        );
    }
    if(props.IconName === 'Refresh'){
        return (
            <svg width="29" height="25" viewBox="0 0 29 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M26.6714 12.8436C26.6714 14.9696 26.0821 17.054 24.9688 18.8652C23.8556 20.6765 22.2621 22.1437 20.3653 23.1039C18.4685 24.0641 16.3427 24.4797 14.2239 24.3045C12.1052 24.1294 10.0764 23.3703 8.36306 22.1117L9.66166 20.3439C11.0482 21.3625 12.69 21.9768 14.4046 22.1185C16.1193 22.2602 17.8396 21.9239 19.3747 21.1469C20.9097 20.3698 22.1992 19.1825 23.1001 17.7167C24.001 16.2509 24.4779 14.5641 24.4779 12.8436H26.6714Z" fill="white"/>
                <path d="M3.67139 12.8436C3.67139 10.6878 4.27736 8.5754 5.42017 6.74742C6.56298 4.91943 8.1966 3.44949 10.1346 2.50529C12.0727 1.56109 14.2371 1.18066 16.381 1.40742C18.5248 1.63417 20.5618 2.45897 22.2594 3.78771L20.9075 5.51502C19.5336 4.43972 17.8852 3.77224 16.1503 3.58874C14.4153 3.40524 12.6637 3.7131 11.0953 4.4772C9.52696 5.24131 8.20493 6.43088 7.2801 7.9102C6.35527 9.38952 5.86488 11.099 5.86488 12.8436L3.67139 12.8436Z" fill="white"/>
                <path d="M25.0312 9.43951L16.5329 6.22882L23.5626 0.474396L25.0312 9.43951Z" fill="white"/>
                <path d="M4.71545 15.2922L13.0461 18.9134L5.74469 24.3173L4.71545 15.2922Z" fill="white"/>
            </svg>
        );
    }
}
