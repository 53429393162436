import styles from "../styles.module.scss";

const scrollToElement = (target) => {
  const element = document.getElementById(target);
  element.scrollIntoView({
    behavior: "smooth",
  });
};

function NavigationLink(props) {
  return (
    <button
      tabIndex={0}
      type="button"
      className={`${
        props.button ? `${styles.akpButton}` : `${styles.akpNavItem}`
      }`}
      onClick={() => {
        scrollToElement(props.LinkTarget);
      }}
    >
      {props.LinkText}
    </button>
  );
}

export default NavigationLink;
