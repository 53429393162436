import styles from "../styles.module.scss";

function PricingDescItem(props) {
  return (
    <div className={styles.akpPricingDescItem}>
      <svg
        width="24"
        height="20"
        viewBox="0 0 24 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22 1L10.9355 16.7233C10.1483 17.8418 8.49585 17.858 7.68703 16.755L2 9"
          stroke="#C40000"
          stroke-width="3"
        />
      </svg>
      <div className={styles.akpPricingDescItemText}>{props.ItemText}</div>
    </div>
  );
}

export default PricingDescItem;
