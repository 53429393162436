import styles from "../styles.module.scss";
import AkpButton from "./button";

function Footer() {
  return (
    <div className={`${styles.akpFooter} ${styles.akpFullBlock}`}>
      <div className={`${styles.akpFooterCont} ${styles.akpBlock}`}>
        <AkpButton secondary ButtonText="Takaisin ylös" />
        <a className={styles.akpFooterLink} href="https://www.valokuvaajat.fi">Valokuvaajat.fi -jäsen</a>
        <div className={styles.akpFooterText}>
          © Asuntokuvaus Pelkkikangas 2024
        </div>
      </div>
    </div>
  );
}

export default Footer;
