import logo from "../logo.svg";
import styles from "../styles.module.scss";
import Navigation from "./navigation";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

function Header() {
  return (
    <div className={`${styles.akpHeader} ${styles.akpFullBlock}`}>
      <div className={`${styles.akpHeaderCont} ${styles.akpBlock}`}>
        <button
          type="button"
          onClick={scrollToTop}
          aria-label="Asuntokuvaus Pelkkikangas logo painike"
        >
          <img
            src={logo}
            className="akp-logo"
            alt="logo"
            aria-label="Asuntokuvaus Pelkkikangas logo"
          />
        </button>
        <Navigation />
      </div>
    </div>
  );
}

export default Header;
